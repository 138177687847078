import {
  Box,
  Button,
  Card,
  CardMedia,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
// import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Alert, Autocomplete } from "@material-ui/lab";
import { endOfDay, isAfter, parseISO } from "date-fns";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { HEADER_PADDING } from "../../AppLayout";
import { Loader } from "../../components";
import { apiUrl, imageUrl } from "../../lib";
import { countryToFlag } from "../../lib/countryToFlag";
import { CashOnDeliveryDialog } from "./components";

interface Props {}

export const Store: React.FC<Props> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const image = imageUrl("/store/bookcover.jpg");
  const [imageWidth, setImageWidth] = React.useState(0);
  const imageRef = React.useRef<HTMLBaseElement>(null);
  const [loaded, setLoaded] = React.useState(false);
  const [codDialogOpen, setCodDialogOpen] = React.useState(false);
  const { hash } = useLocation();

  React.useEffect(() => {
    setImageWidth(imageRef.current ? imageRef.current.clientWidth : 0);
  }, [loaded]);

  const isPaymentSuccessful = hash.startsWith("#success");

  const shipToOptions = [
    {
      isoAlpha2: "MT",
      label: "Malta (free delivery)",
    },
    //{
    //  isoAlpha2: "EU",
    //  label: "European Union",
    //},
    //{
    //  isoAlpha2: "GB",
    //  label: "United Kingdom",
    //},
    //{
    //  isoAlpha2: "US",
    //  label: "United States",
    //},
    //{
    //  isoAlpha2: "AU",
    //  label: "Australia",
    //},
  ];

  const fullPrice = 44.99;
  const discountPrice = 39.99;
  const discountId = "IermpJ4c";
  const discountPriceEndDate = endOfDay(parseISO("2022-12-04"));

  const shouldApplyDiscount = !isAfter(new Date(), discountPriceEndDate);

  return (
    <React.Fragment>
      {isPaymentSuccessful && (
        <Alert severity="success">
          Thank you for your purchase. An email has been sent to you with the
          order details.
        </Alert>
      )}

      <Typography
        variant="h1"
        color="primary"
        align={isMobile ? "center" : "left"}
      >
        Store
      </Typography>

      <div style={{ paddingTop: isMobile ? undefined : HEADER_PADDING / 2 }}>
        <Grid container spacing={2}>
          <Grid item md={5} sm={12} xs={12}>
            {!loaded ? (
              <Loader imageSrc={image} onImgLoad={(e) => setLoaded(true)} />
            ) : (
              <Card
                ref={imageRef}
                elevation={0}
                style={{
                  height: imageWidth,
                  margin: isMobile ? "auto" : "unset",
                }}
              >
                <CardMedia
                  image={image}
                  style={{
                    height: imageWidth,
                    backgroundSize: "contain",
                    backgroundPosition: isMobile ? "center" : "left top",
                  }}
                />
              </Card>
            )}
          </Grid>

          <Grid item md={7} sm={12} xs={12}>
            <Typography
              variant="h2"
              gutterBottom
              align={isMobile ? "center" : undefined}
            >
              A Plant-Based Maltese Kitchen
            </Typography>
            <br />
            <Typography
              variant="body1"
              gutterBottom
              align={isMobile ? "justify" : undefined}
            >
              The high consumption of animal products and highly processed foods
              have time and time again proven to be harmful to our well-being
              and that of the planet.
            </Typography>
            <br />
            <Typography
              variant="body1"
              gutterBottom
              align={isMobile ? "justify" : undefined}
            >
              The plant-based Maltese kitchen revisits traditional recipes of
              the past and shapes them into more nutritious versions free from
              animal products.
            </Typography>
            <br />
            <Typography
              variant="body1"
              gutterBottom
              align={isMobile ? "justify" : undefined}
            >
              Whether you are vegan, vegetarian, pescetarian or simply
              interested in cutting down on your consumption of meat, eggs and
              dairy - this book offers a seasonal variety of delicious and easy
              to follow plant-based recipes influenced by the rich culinary
              history of the Maltese islands.
            </Typography>
            <form
              action={`${apiUrl}/create-checkout-session`}
              method="post"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <br /> <br />
              <Typography
                component="span"
                style={{ marginRight: 24, marginBottom: 12 }}
              >
                {shouldApplyDiscount && (
                  <React.Fragment>
                    <input
                      type="hidden"
                      name="couponId"
                      value={discountId}
                    ></input>
                    <del>&euro;{fullPrice}</del>
                    &nbsp;
                  </React.Fragment>
                )}
                <strong>
                  &euro;{shouldApplyDiscount ? discountPrice : fullPrice}
                </strong>
              </Typography>
              <FormControlLabel
                label="copies"
                style={{ marginBottom: 12 }}
                control={
                  <input
                    name="quantity"
                    type="number"
                    defaultValue={1}
                    min={1}
                    max={999}
                    width={150}
                    style={{ marginRight: 8 }}
                  />
                }
              />
              <Autocomplete
                style={{ width: 225, marginBottom: 12 }}
                options={shipToOptions.map((o) => o.isoAlpha2)}
                defaultValue="MT"
                renderOption={(c) => (
                  <React.Fragment>
                    <Box mr={1} component="span">
                      {c === "EU" ? `🇪🇺` : countryToFlag(c)}
                    </Box>

                    {shipToOptions.find((s) => s.isoAlpha2 === c)?.label ?? ""}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="shipTo"
                    label="Ship to"
                    required
                  />
                )}
              />
              <Button
                role="link"
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                fullWidth={isMobile}
              >
                <ShoppingCartIcon /> &nbsp; Order{" "}
                {isPaymentSuccessful ? "Again" : "Now"}
              </Button>
            </form>
            {/* &nbsp; &nbsp;
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => setCodDialogOpen(true)}
            >
              <LocalAtmIcon /> &nbsp;Cash on delivery
            </Button> */}
          </Grid>
        </Grid>
      </div>

      <CashOnDeliveryDialog
        open={codDialogOpen}
        onClose={() => setCodDialogOpen(false)}
      />
    </React.Fragment>
  );
};
